<template>
  <div class="intro" id="hmmember">
    <div class="intro-content">
      <h3>BECOME A</h3>
      <h1>MEMBER</h1>

      <p>Becoming a TayoCash member is quick and simple.
Register now to experience cashless convenience together.</p>

      <h4>DOWNLOAD NOW ON:</h4>
      <div class="download">
        <a href="https://apps.apple.com/ph/app/tayocash/id1547566625" class="apple" target="_blank">
          <div class="apple-logo"></div>
          <div class="text">
            Download on the
            <span>App Store</span>
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.tayocash" class="android" target="_blank">
          <div class="android-logo"></div>
          <div class="text">
            GET IT ON
            <span>Google Play</span>
          </div>
        </a>
      </div>
    </div>
    <div class="image-div">
      <img class="people" src="@/assets/md1.png" alt="Tayocash">
    </div>
  </div>
</template>
<script>
export default {
  name: 'content1'
}
</script>

<style scoped lang='scss'></style>
